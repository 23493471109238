import "./ViewportMessage.css";

/* if the users viewport is too large display this message. */
function ViewportMessage() {
  return (
    <div className="ViewportMessageContainer">
      <h1 className="ViewportMessage">
        Please access the website using a mobile device.
      </h1>
    </div>
  );
}

export default ViewportMessage;
