import "./Home.css";
import Header from "../../components/header/Header.js";
import ViewportMessage from "../../components/viewport-message/ViewportMessage.js";
import HeaderFooter from "../../components/header-footer/HeaderFooter.js";
import Preview from "../../components/preview/Preview.js";

function Home() {
  return (
    <div className="Home">
      {/* display message if viewport is too large */}
      <div className="HomeDisplayMessage">
        <ViewportMessage />
      </div>

      {/* display if viewport is small enough */}
      <div className="HomeMainContent">
        {/* header with icon and text logo */}
        <Header />
        <HeaderFooter />

        {/* add gallery previews */}

        {/* Preview 00 */}
        <Preview
          location="Historic Center, Cusco, Peru"
          title="Parade In Cusco"
          date="September 24th, 2024"
          route="parade-in-cusco"
        />
      </div>
    </div>
  );
}

export default Home;
