import { BrowserRouter, Routes, Route } from "react-router-dom";

/* import pages */
import Home from "./pages/home/Home.js";
import ParadeInCusco from "./pages/galleries/ParadeInCusco.js";

function App() {
  return (
    <div className="App">
      {/* add page routes here using react router dom */}
      <BrowserRouter>
        <Routes>
          {/* home page routes - (00) */}
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />

          {/* gallary page routes */}

          {/* Parade in cusco route - (01) */}
          <Route path="/parade-in-cusco" element={<ParadeInCusco />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
