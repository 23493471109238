import "./Preview.css";
import { useNavigate } from "react-router-dom";

function Preview(props) {
  const navigate = useNavigate();

  const gotToNewPage = () => {
    navigate(props.route);
  };

  return (
    /* add on click route for the entire div */
    <div className="PreviewContainer" onClick={() => gotToNewPage()}>
      <p className="PreviewText PreviewLocation">{props.location}</p>
      <h1 className="PreviewText PreviewTitle">{props.title} </h1>
      <p className="PreviewText PreviewDate">{props.date}</p>
    </div>
  );
}

export default Preview;
