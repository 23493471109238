import "./HeaderFooter.css";

function HeaderFooter() {
  return (
    <div className="HeaderFooter">
      <h1>Last Update: September 24th, 2024</h1>
    </div>
  );
}

export default HeaderFooter;
