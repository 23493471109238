import "./Header.css";
import TextLogo from "../../assets/logos/textLogo.svg";
import IconLogo from "../../assets/logos/iconLogo.svg";

function Header() {
  return (
    <div className="Header">
      {/* text logo, on click navigates home */}
      <a href="/" className="TextLogo">
        <img src={TextLogo} alt="text logo" className="TextLogoImg" />
      </a>

      <a href="/" className="IconLogo">
        <img src={IconLogo} alt="icon logo" className="IconLogoImg" />
      </a>
    </div>
  );
}

export default Header;
