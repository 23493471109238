import "./ParadeInCusco.css";
import IconLogo from "../../assets/logos/iconLogo.svg";
import CloseIcon from "../../assets/icons/CloseIcon.svg";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../components/header/Header.js";
import HeaderFooter from "../../components/header-footer/HeaderFooter.js";
import ViewportMessage from "../../components/viewport-message/ViewportMessage.js";

import Photo00 from "../../assets/photos/parade-in-cusco/photo00.jpg";
import Photo01 from "../../assets/photos/parade-in-cusco/photo01.jpg";
import Photo02 from "../../assets/photos/parade-in-cusco/photo02.jpg";
import Photo03 from "../../assets/photos/parade-in-cusco/photo03.jpg";
import Photo04 from "../../assets/photos/parade-in-cusco/photo04.jpg";

import PageTitle from "../../assets/titles/ParadeInCuscoTitle.svg";
import EnlargeIcon from "../../assets/icons/EnlargeIcon.svg";

function ParadeInCusco() {
  const navigate = useNavigate();
  const [SelectedPhoto, setSelectedPhoto] = useState(Photo00);
  const [selectedPhotoNumber, setSelectedPhotoNumber] = useState(0);
  const [ExpandPhoto, setExpandPhoto] = useState(false);

  function changeSelectedPhoto(num) {
    if (num == 0) {
      setSelectedPhotoNumber(0);
      setSelectedPhoto(Photo00);
    } else if (num == 1) {
      setSelectedPhotoNumber(1);
      setSelectedPhoto(Photo01);
    } else if (num == 2) {
      setSelectedPhotoNumber(2);
      setSelectedPhoto(Photo02);
    } else if (num == 3) {
      setSelectedPhotoNumber(3);
      setSelectedPhoto(Photo03);
    } else if (num == 4) {
      setSelectedPhotoNumber(4);
      setSelectedPhoto(Photo04);
    } else {
      console.log("no more photos");
    }
  }

  if (ExpandPhoto === false) {
    return (
      <div className="ParadeInCuscoShowScreen">
        {/* display message if viewport is too large */}
        <div className="ParadeDisplayMessage">
          <ViewportMessage />
        </div>

        {/* display if viewport is small enough */}
        <div className="ParadeMainContent">
          <div className="ParadeHomeAndExpand">
            {/* route home */}
            <button className="HomePageIcon" onClick={() => navigate("/")}>
              <img src={IconLogo} alt="icon logo" className="ParadeIconLogo" />
            </button>
          </div>
          <div className="ParadeTopPageContainer" onClick={() => navigate("/")}>
            <button className="PageTitleButton">
              <img
                src={PageTitle}
                alt="page title"
                className="ParadePageTitle"
              />
            </button>
          </div>
          <div className="PhotoContainer">
            {/* expand to full screen */}
            <div className="ExpandPhotoContainer">
              <button
                className="ExpandPhoto"
                onClick={() => setExpandPhoto(true)}
              >
                <img
                  src={EnlargeIcon}
                  alt="expand photo"
                  className="IconEnlarge"
                />
              </button>
            </div>

            <img src={SelectedPhoto} alt="gallery photo" className="Photo" />
          </div>
          <div className="PhotoLowerDataContainer">
            <h2 className="ParadeGalleryDate">September 24th, 2024.</h2>
            <h2 className="ParadeGalleryPhotoNumber">
              {selectedPhotoNumber + 1}/5
            </h2>
          </div>
          <div className="LowerParadeMainContainer">
            <button
              className="ChangePhotoButton PreviousPhotoButton"
              onClick={() => changeSelectedPhoto(selectedPhotoNumber - 1)}
            >
              Previous
            </button>
            <button
              className="ChangePhotoButton NextPhotoButton"
              onClick={() => changeSelectedPhoto(selectedPhotoNumber + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="ParadeInCuscoFullScreen">
        <div className="PhotoContainerFullScreen">
          <img src={SelectedPhoto} alt="gallery photo" className="Photo" />
          <button className="ClosePhoto" onClick={() => setExpandPhoto(false)}>
            <img
              src={CloseIcon}
              alt="close full screen photo"
              className="CloseIcon"
            />
          </button>
        </div>
      </div>
    );
  }
}

export default ParadeInCusco;
